<template>
  <div class="members divider">
		<div class="member" :style="{ 'background-image': 'url(img/members/' + member.name + '-min.jpg)', 'background-position': member.offset }" v-for="member in members" :key="member.name">
			<div class="member__overlay">
				<div class="member__overlay__text">
					<h2>{{member.name}}</h2>
					<h3>{{member.instrument}}</h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'Members',
	data() {
		return {
			members: [
				{
					'name': 'Jos',
					'instrument': 'Zang, Bas',
					'offset': '30% 50%'
				},
				{
					'name': 'Kim',
					'instrument': 'Zang',
					'offset': '50% 50%'
				},
				{
					'name': 'Nils',
					'instrument': 'Drums',
					'offset': '50% 50%'
				},
				{
					'name': 'Didier',
					'instrument': 'Gitaar',
					'offset': '50% 50%'
				},
				{
					'name': 'Jochem',
					'instrument': 'Toetsen',
					'offset': '50% 50%'
				},
				{
					'name': 'Jelmer',
					'instrument': 'Gitaar',
					'offset': '50% 50%'
				},
			]
		}
	},
  components: {
  }
}
</script>

<style lang="scss">
.members {
  flex-wrap: wrap;
	display: flex;
	width: 100%;
}
.member {
	height: 300px;
	flex: 1;
	flex-basis: 33.333333%;
	overflow: hidden;
	position: relative;
	background-size: cover;
	// background-position: 50% 50%;
	&:hover {
		& .member__overlay {
			opacity: 0.9;
		}
	}
	&__overlay {
		transition: opacity 0.3s ease-in-out;
		background: $blue;
		opacity: 0;
		height: 100%;
		position: absolute;
		width: 100%;
		text-align: center;
		& * {
			color: white;
		}
		&__text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	& img {
		width: 100%;
	}
}

@media only screen and (max-width: 900px) {
  .member {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .member {
    // flex-basis: 100%;
  }
}

</style>
