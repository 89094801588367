<template>
  <div class="navigation">
		<div class="navigation__content">
			<a @click="scroll('home')"><img class="navigation__logo" src="img/logo_navigation.svg" alt="Band logo of Beauty and the Band."></a>
			<ul class="navigation__links">
				<li class="navigation__link"><a @click="scroll('about')">BAND</a></li>
				<li class="navigation__link"><a @click="scroll('media')">IN BEELD</a></li>
				<li class="navigation__link"><a @click="scroll('shows')">SHOWS</a></li>
				<li class="navigation__link"><a @click="scroll('contact')">CONTACT</a></li>
				<li class="navigation__link"><a href="https://www.facebook.com/beautyandthebandmusic" target="_blank"><img class="navigation__link__icon" src="img/facebook.png" alt="Facebook logo"></a></li>
				<li class="navigation__link"><a href="https://www.instagram.com/beauty.theband/" target="_blank"><img class="navigation__link__icon" src="img/instagram.png" alt="Instagram logo"></a></li>
			</ul>
		</div>
  </div>
</template>

<script>

export default {
  name: 'Navigation',
  components: {
  },
	methods: {
		scroll(elementId) {
			var element = document.getElementById(elementId)
			var headerOffset = window.innerWidth > 600 ? 100 : 175
			var elementPosition = element.getBoundingClientRect().top + document.documentElement.scrollTop
			var offsetPosition = elementPosition - headerOffset
      
      window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
      })
		}
	}
}
</script>

<style lang="scss">
.navigation {
	background: $beige;
	position: fixed;
	width: 100%;
	z-index: 10;
	box-shadow: $shadow-200;
	&__content {
		padding: 0 $spacing-6;
		width: 100%;
		height: $navigation-height;
		align-items: center;
		display: inline-flex;
		justify-content: space-between;
	}
	&__logo {
		cursor: pointer;
		height: 40px;
	}
	&__links {
		display: flex;
	}
	&__link {
		vertical-align: middle;
		cursor: pointer;
		list-style: none;
		margin: 0 $spacing-4;
		font-weight: 700;
		font-size: 13px;
		align-items: center;
		border-bottom: 2px solid transparent;
		&:hover {
			border-bottom: 2px solid $blue;
			// color: $light-blue;
		}
		&__icon {
			height: 16px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.navigation {
		&__content {
			flex-direction: column;
			padding: $spacing-6 $spacing-6 $spacing-4;
			height: auto;
		}
		&__logo {
			margin-bottom: $spacing-5;
		}
		&__links {
			flex-wrap: wrap;
			justify-content: center;
		}
		&__link {
			margin-bottom: $spacing-3;
		}
	}
}

</style>
