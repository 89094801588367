<template>
  <div class="top" id="home">
		<Navigation></Navigation>
    <div class="top__image">
      <img v-if="$mq == 'lg'" src="img/header-wide.jpg" alt="The six band members, with a large render of the logo in the center.">
      <img v-else src="img/header.jpg" alt="The six band members, with a large render of the logo in the center.">
    </div>
  </div>
</template>

<script>
import Navigation from './Navigation'

export default {
  name: 'Top',
  components: {
		Navigation
  }
}
</script>

<style lang="scss">
.top {
  // height: 100vh;
  &__image {
    height: 100%;
    padding-top: $navigation-height;
    position: relative;
    overflow: hidden;
    & img {
      // position: absolute;
      width: 100%;
      min-height: calc(100vh - #{$navigation-height});
      // height: calc(100vh - #{$navigation-height});
      // min-width: 100%;
      // top: $navigation-height;
      // left: 50%;
      // transform: translateX(-50%);
      // height: calc(100% - #{$navigation-height});
    }
  }
}

@media only screen and (max-width: 600px) {
  .top {
    height: auto;
    &__image {
      padding-top: 180px;
      & img {
        // position: static;
        // transform: none;
        width: 100%;
        min-height: auto;
      }
    }
  }
}
</style>
