<template>
  <div class="videos section" id="media">
		<h1 class="section__title">In beeld</h1>
    <div class="video">
      <div class="video__container">
        <iframe src="https://www.youtube.com/embed/lO3sDDb0z8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Videos',
  components: {
  }
}
</script>

<style lang="scss">
.videos {
  background: white;
  text-align: center;
}

.video {
  margin: 0 auto;
  width: 1000px;
  max-width: 100%;
}

.video__container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

</style>
