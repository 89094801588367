<template>
  <div class="about section" id="about">
		<h1 class="section__title">Band</h1>
    <div class="about__content">
      <div class="about__content__text">
        <h2>Beauty and the Band</h2>
        <p>Deze Beauty heeft ervoor gekozen haar beest thuis te laten! Gelukkig komt ze niet alleen en neemt ze de band mee.  Dit feestende zestal maakt van jouw cantus een waar sprookje om nog jaren over na te praten! In 120 minuten zetten zij jouw feesttent, festival of club om tot 1-groot meezing festijn.
        <br><br>
        Met een paar honderd shows in de benen weten deze Beauty & haar beesten van aanpakken! Bewapend met een lading hits én heel veel bier zijn deze party animals jouw medicijn om het keeltje te smeren!
        </p>
      </div>
      <div class="about__content__image">
        <img src="img/band.jpg" alt="The band on stage">
      </div>
    </div>
    <div class="about__content">
      <div class="about__content__image">
        <img src="img/cantus.jpg" alt="An audience at one of the band's previous gigs">
      </div>
      <div class="about__content__text">
        <h2>De Cantus</h2>
        <p>
          Dit legendarische zangfestijn is niet meer weg te denken uit Nederland! Dit fenomeen is afkomstig uit de studentenwereld en ondertussen overgewaaid naar menig festival. 
          <br><br>
          In 120 minuten worden de deelnemers van een cantus door Beauty and the Band uitgedaagd om allerlei hitjes van toen en nu heel hard mee te zingen. Omdat iedereen mee moet zingen ontstaat er al snel een gezellige sfeer!
          <br><br>
          Verder is de opzet simpel: de locatie is gevuld met bezoekers zittende aan lange biertafels. Daaromheen bevinden zich verschillende barren waar bier gehaald kan worden. 
          <br><br>
          Traditiegetrouw start een cantus altijd met het Wilhelmus gevolgd door een ‘het cantus lied’ waarna alle deelnemers hun glas leegdrinken. Om ervoor te zorgen dat iedereen de liedjes uit volle borst mee kan zingen worden er cantusbundels uitgereikt. Dit zijn kleine boekjes met alle teksten van de liedjes die gedurende de avond voorbij zullen komen. Niemand kan hier onderuit komen en meezingen is dus echt een must! 
          <br><br>
          Beauty and the Band brengt de cantus op een speelse manier waarbij iedereen welkom is! Zien wij jou binnenkort bij ons op de cantus?
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  components: {
  }
}
</script>

<style lang="scss">
.about {
  height: auto;
  &__content {
    align-items: center;
    display: flex;
    &:first-of-type {
      margin-bottom: $spacing-8;
    }
    & > div {
      width: 50%;
    }
    &__text {
      padding: 0 $spacing-8;
    }
    &__image {
      padding: 0 $spacing-8;
      & img {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .about {
    &__content {
      flex-direction: column;
      &:first-of-type {
        flex-direction: column-reverse;
      }
      & > div {
        width: 100%;
      }
      &__text {
        padding: 0;
      }
      &__image {
        margin-bottom: $spacing-6;
        padding: 0;
      }
    }
  }

}
</style>
