<template>
  <div class="contact section" id="contact">
		<h1 class="section__title">Contact</h1>
    <p class="contact__text">
      <a href="mailto:boekingen@beautyandtheband.nl" class="contact__email">boekingen@beautyandtheband.nl</a><br><br>
      Wil jij ook een te gekke cantus bouwen? Met plezier en overgave bouwen wij de leukste cantus van Nederland!
    </p>
    <form class="contact__form"  @submit.prevent="sendMail" v-if="!sent">
			<input name="name" type="text" placeholder="Naam*" required v-model="email.name">
			<input name="email" type="email" placeholder="Email*" required v-model="email.email">
			<input name="phone" type="text" placeholder="Telefoonnummer" v-model="email.phone">
			<textarea name="message" placeholder="Bericht*" required v-model="email.message"></textarea>
      <input type="submit" class="contact__submit button" value="Verstuur">
    </form>
    <p v-else>Bedankt voor je bericht! We reageren zo snel mogelijk.</p>
  </div>
</template>

<script>
const axios = require('axios')
export default {
  name: 'Contact',
  data() {
    return {
      sent: false,
      email: {
        'name': '',
        'email': '',
        'phone': '',
        'message': ''
      }
    }
  },
  components: {
  },
  methods: {
    async sendMail() {
      if (this.sent) return
      console.log('Sending email')
      let data = null
      await axios.post('https://www.beautyandtheband.nl/email.php', this.email)
        .then((response) => data = response.data
      )
      this.sent = true
      console.log(data)
    }
  }
}
</script>

<style lang="scss">
.contact {
  background: #b0bfda;
  box-shadow: $shadow-200;
  text-align: center;
  & .section__title {
    margin-bottom: $spacing-4;
  }
  &__email {
    cursor: pointer;
    font-weight: 700;
  }
  &__text {
    margin: 0 auto $spacing-6;
    width: 400px;
    max-width: 100%;
  }
  &__form {
    max-width: 100%;
    width: 600px;
    margin: auto;
    & input, textarea {
      display: inline;
      width: 100%;
      margin-bottom: $spacing-5;
    }
    & textarea {
      height: 200px;
    }
  }
  &__submit {
    cursor: pointer;
  }
}
</style>
