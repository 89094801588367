<template>
  <div id="app">
    <Top></Top>
    <About></About>
    <Members></Members>
    <Videos></Videos>
    <!-- <div class="divider"></div> -->
    <Photos></Photos>
    <!-- <Shows></Shows> -->
    <Contact></Contact>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "./components/Top";
import About from "./components/About";
import Members from "./components/Members";
import Photos from "./components/Photos";
import Videos from "./components/Videos";
import Contact from "./components/Contact";
import Bottom from "./components/Bottom";

export default {
  name: "App",
  components: {
    Top,
    Bottom,
    About,
    Members,
    Photos,
    Videos,
    Contact,
  },
};
</script>

<style lang="scss"></style>
