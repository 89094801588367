<template>
  <div class="bottom section">
    <div class="bottom__content">
      <div class="bottom__column">
        <h3>Contact info</h3>
        <a href="mailto:info@beautyandtheband.nl">info@beautyandtheband.nl</a>
      </div>
      <div class="bottom__logo">
        <img src="img/logo.svg" alt="Band logo of Beauty and the Band.">
      </div>
      <div class="bottom__column">
        <h3>Extra info</h3>
        <a href="/">Press kit</a>
      </div>
    </div>
    <div class="bottom__privacy">
      <a href="/" class="bottom__privacy">Privacy statement</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Bottom',
  components: {
  }
}
</script>

<style lang="scss">
.bottom {
  & * {
    color: white;
  }
  $logo-width: 125px;
  background: $blue;
  padding: $spacing-8 $spacing-10 $spacing-6;
  &__content {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    width: $logo-width;
    & img {
      width: 100%;
    }
  }
  &__column {
    margin: 0 $spacing-8;
    padding: $spacing-4 $spacing-6;
    width: calc(0.5 * (100% - #{$logo-width}));
    & h3 {
      font-size: 16px;
      font-weight: 600;
    }
    &:first-of-type {
      border-right: 2px solid white;
    }
    &:last-of-type {
      border-left: 2px solid white;
    }
  }
  &__privacy {
    text-align: center;
    font-size: 12px;
    margin-top: $spacing-8;

  }
}

@media only screen and (max-width: 600px) {
  .bottom {
    padding: $spacing-8 $spacing-6 $spacing-6;
    &__content {
      flex-direction: column;
    }
    &__logo {
      margin: $spacing-6 0;
    }
    &__column {
      width: 100%;
      border-left: 2px solid white;
      &:first-of-type {
        border-right: none;
      }
    }
  }
}
</style>
