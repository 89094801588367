<template>
  <div class="photos section">
		<!-- <h1 class="section__title">Foto's</h1> -->
    <div class="photos__gallery">
      <div class="photo" v-for="n in 9" :key="'gallery_' + n" :style="{ 'background-image': 'url(img/gallery/gallery' + n + '-min.jpg)' }">
        <!-- <img :src="'img/gallery/gallery' + n + '.jpg'"> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Photos',
  components: {
  }
}
</script>

<style lang="scss">
.photos.section {
  padding: 0;
}
.photos__gallery {
  flex-wrap: wrap;
	display: flex;
	width: 100%;
}
.photo {
	height: 300px;
	flex: 1;
	flex-basis: 33.333333%;
  overflow: hidden;
  & img {
    width: 100%;
  }
	background-size: cover;
	background-position: 50% 50%;
}


@media only screen and (max-width: 900px) {
  .photo {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .photo {
    flex-basis: 100%;
  }
}
</style>
